import React from 'react';
import Logo from '../../../assets/logomark.svg';
import { Flex, Image, Text } from 'rebass/styled-components';

const Footer = () => (
  <Flex
    as="footer"
    flexDirection={['column', 'row']}
    width={1}
    justifyContent={['center', 'space-between']}
    alignItems="center"
    mt={[7, null, 140]}
    mb={[7, null, 10]}
    maxWidth={1232}
    mx="auto"
    px={[5]}
  >
    <Image src={Logo} alt="logomark" height={['36px']} mr={[0, 2]} />
    <Flex flexDirection={['column', 'row']}>
      <Text mt={[7, 0]} mr={[0, 2]} color="text" opacity={0.8}>
        © 2020 All Rights Reserved
      </Text>
      <Flex flexDirection="row" mt={[2, 0]}>
        <a href="https://govastly.com/terms-of-use" style={{ textDecoration: 'none' }}>
          <Text mr={[5, 2]} color="text" opacity={0.8}>
            Terms of Use
          </Text>
        </a>
        <a href="https://govastly.com/about/privacy-policy" style={{ textDecoration: 'none' }}>
          <Text color="text" opacity={0.8}>
            Privacy Policy
          </Text>
        </a>
      </Flex>
    </Flex>
  </Flex>
);

export default Footer;
